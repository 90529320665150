
.main-wrapper {
    height: 100%;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;

    .table-content {
        height: 100%;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .customTable {
            width: 100%;
            flex: 1;
            margin-top: 20px;

            .show-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .upload-file-content {
        height: 100%;

        .pages-center {
            margin-bottom: 0;
        }
    }
}
